import React from 'react';
import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Slider from '../components/slider';
import About from '../features/about';
import Project from '../features/project';
import RoadMap from '../features/roadmap';
import Work from '../features/work';
import Blog from '../features/blog';
import useFetch from '../hooks/useFetch';


function HomeOne(props) {

    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/homepage";
    const query = "?&&populate=*,About,About.Image,About.Image.Image,About.Button,Services,Services.Item,Latest,Latest.Button,Gallery.Image.Image,Reviews,Reviews.Review,Home,Home.Button,Home.Video,Home.VideoPlaceholder,Home.Logo,Home.Logo.Image";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    return (
        <div className='home-1'>

            <Slider data={data.data.attributes.Home} />

            <About data={data.data.attributes.About} />

            <RoadMap data={data.data.attributes.Services} />
            <Blog data={data.data.attributes.Latest} />
            <Project data={data.data.attributes.Gallery} />


            <Work data={data.data.attributes.Reviews} />

            {/* <Team data={dataTeam} /> */}


            {/* <Partner data={dataPartner} /> */}

            {/* <FAQ data={dataFaq} /> */}
        
        </div>
    );
}

export default HomeOne;