import React from 'react';
import PageTitle from '../components/pagetitle';
import Team from '../features/team/home-v2';
import useFetch from '../hooks/useFetch';


function Management(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/management";
    const query = "?populate=*,Title,TeamMember,TeamMember.Image,TeamMember.Image.Image,TeamMember.SocialMedia";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    return (
        <div>
            <PageTitle title={data.data.attributes.Title ?? ""} />

            <Team data={{TeamMember : data.data.attributes.TeamMember}} />

        </div>
    );
}

export default Management;