import PageTitle from '../components/pagetitle';
import useFetch from '../hooks/useFetch';

function History(props) {

    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/history";
    const query = "?populate=*,Image.Image,Text";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    return (
        <div className='community'>
            <PageTitle title={data.data.attributes.Title} />

            <section className="tf-section tf-video">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-about mb-51 mobie-40">
                                <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                                    <div className="post-details">
                                        <p className="mb-5">
                                            <div dangerouslySetInnerHTML={{__html: data.data.attributes.Text}} />
                                        </p>
                                        <div className="image">
                                            <img src={backend + data.data.attributes.Image.Image.data.attributes.formats.medium.url} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default History;