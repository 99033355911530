import React from 'react';
import PageTitle from '../components/pagetitle';
import Project from '../features/project/nftitem';
import useFetch from '../hooks/useFetch';


function Gallery(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/gallery";
    const query = "?populate=*,Gallery.Image,Gallery.Image.Image";
    const url = backend + endpoint + query;

    const { isPending, error, data } = useFetch(url);

    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;

    return (
        <div className='page-nft'>
            <PageTitle title={(data.data.attributes.PageTitle ?? "")} />

            <Project data={data.data.attributes.Gallery} />
        </div>
    );
}

export default Gallery;