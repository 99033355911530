import React , { useState , useEffect } from 'react';
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import './styles.scss';
import logo from '../../assets/images/logo/ciss-logo-150-3.png'



const Header = (props) => {

    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    const menus = [
        {
            id: 1,
            isEnabled: props.menu[0].isEnabled,
            name: props.menu[0].title,
            links: props.menu[0].path,
        },
        {
            id: 2,
            isEnabled: props.menu[1].isEnabled,
            name: props.menu[1].title,
            links: props.menu[1].path,
            hasChildren : props.menu[2].isEnabled || props.menu[3].isEnabled || props.menu[4].isEnabled,
            namesub: [
                {
                    id: 1,
                    isEnabled: props.menu[2].isEnabled,
                    name: props.menu[2].title,
                    links: props.menu[2].path,
                },
                {
                    id: 2,
                    isEnabled: props.menu[3].isEnabled,
                    name: props.menu[3].title,
                    links: props.menu[3].path,
                },
                {
                    id: 3,
                    isEnabled: props.menu[4].isEnabled,
                    name: props.menu[4].title,
                    links: props.menu[4].path,
                }
    
            ]
        },
        {
            id: 3,
            isEnabled: props.menu[5].isEnabled,
            name: props.menu[5].title,
            links: props.menu[5].path,
            hasChildren : props.menu[6].isEnabled && props.menu[7].isEnabled && props.menu[8].isEnabled && props.menu[9].isEnabled && props.menu[10].isEnabled,
            namesub: [
                {
                    id: 1,
                    isEnabled: props.menu[6].isEnabled,
                    name: props.menu[6].title,
                    links: props.menu[6].path,
                },
                {
                    id: 2,
                    isEnabled: props.menu[7].isEnabled,
                    name: props.menu[7].title,
                    links: props.menu[7].path,
                },
                {
                    id: 3,
                    isEnabled: props.menu[8].isEnabled,
                    name: props.menu[8].title,
                    links: props.menu[8].path,
                },
                {
                    id: 4,
                    isEnabled: props.menu[9].isEnabled,
                    name: props.menu[9].title,
                    links: props.menu[9].path,
                },
                {
                    id: 5,
                    isEnabled: props.menu[10].isEnabled,
                    name: props.menu[10].title,
                    links: props.menu[10].path,
                },
                {
                    id: 6,
                    isEnabled: props.menu[11].isEnabled,
                    name: props.menu[11].title,
                    links: props.menu[11].path,
                },
            ]
        },
        {
            id: 5,
            isEnabled: props.menu[12].isEnabled,
            name: props.menu[12].title,
            links: props.menu[12].path,
            hasChildren : props.menu[13].isEnabled && props.menu[14].isEnabled && props.menu[15].isEnabled,
            namesub: [
                {
                    id: 1,
                    isEnabled: props.menu[13].isEnabled,
                    name: props.menu[13].title,
                    links: props.menu[13].path,
                },
                {
                    id: 2,
                    isEnabled: props.menu[14].isEnabled,
                    name: props.menu[14].title,
                    links: props.menu[14].path,
                },
                {
                    id: 3,
                    isEnabled: props.menu[15].isEnabled,
                    name: props.menu[15].title,
                    links: props.menu[15].path,
                }
            ],
        },
        {
            id: 6,
            isEnabled: props.menu[16].isEnabled,
            name: props.menu[16].title,
            links: props.menu[16].path,
        },
        
    ];
    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img className="header__logo__image" src={logo} alt="CIS Inc" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">

                            {
                                menus.map((data,idx) => (
                                    (data.isEnabled &&
                                    <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.hasChildren && data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}>
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.hasChildren && data.namesub && 
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        (submenu.isEnabled &&
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.name}</NavLink></li>
                                                    )))
                                                }
                                            </ul>
                                        }
                                        
                                    </li>
                                )))
                            }
                        </ul>
                    </nav>
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
        </header>
    );
}

export default Header;