import React from 'react';
import PropTypes from 'prop-types';

RoadMapItem.propTypes = {
    item: PropTypes.object,
};

function RoadMapItem(props) {
    const {item} = props;
    return (
        <div key={item.id} className={`rm-box ${item.id %2 === 0 ? "right": "left"}`} data-aos="zoom-in" data-aos-duration="1200">
            <div className='corner-box normal'>
                <h5>{item.Title}</h5>
                <ul>
                    <div className="text">{item.Text}</div>
                </ul>
            </div>
        </div>
    );
}

export default RoadMapItem;