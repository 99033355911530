import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {

    const {item} = props;
    const backend = process.env.REACT_APP_BACKEND_URL;

    return (
        <div className="img-box">
            <img src={backend + item.Image.data.attributes.formats.small.url} alt={item.Alt} />
            <div className="content">
                <Link to={(item.Url ?? '')}>{(item.Description) ?? ''}</Link>
            </div>
        </div>
    );
}

export default ProjectItem;