import React, { useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import PageTitle from '../components/pagetitle';
import useFetch from '../hooks/useFetch';
import axios from 'axios';



function Contact(props) {
    
    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [nameIsValid, setNameIsValid] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [message, setMessage] = useState('');
    const [messageTouched, setMessageTouched] = useState(false);
    const [messageIsValid, setMessageIsValid] = useState(false);

    const [serverMessage, setServerMessage] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            axios({
                method: 'post',
                url: '/sendMail/send.php',
                headers: { 'content-type': 'application/json' },
                data: JSON.stringify([
                    name,
                    phone,
                    email,
                    message,
                ])
            }).then(result => {
                if (result.message) {
                    setServerMessage(result.message);
                    setMailSent(true);
                }
            }).catch(error => {
                setServerMessage("Something went wrong...");
                setMailSent(false);
            });
        } else {
            setServerMessage("Please review your details and try again.");
        }
    }
    const validateForm = () => {
        return nameIsValid && emailIsValid && phoneIsValid && messageIsValid;
    }
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/contact";
    const query = "?populate=*,Form,RightDetails,RightDetails.ListItem,LeftDetails,LeftDetails.ListItem";
    const url = backend + endpoint + query;

    const { isPending, error, data } = useFetch(url);

    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;

    const location = (data.data.attributes.Location) ?? "";
    const LeftTitle = (data.data.attributes.LeftTitle) ?? "";
    const RightTitle = (data.data.attributes.RightTitle) ?? "";
    const LeftDetails = (data.data.attributes.LeftDetails) ?? "";
    const RightDetails = (data.data.attributes.RightDetails) ?? "";
    
    const FormTitle = (data.data.attributes.Form && data.data.attributes.Form.Title) ?? "";
    const FormSubtitle = (data.data.attributes.Form && data.data.attributes.Form.Subtitle) ?? "";

    return (
        <div>
            <PageTitle title='Contact' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="content-about m-b50 mobie-40" data-aos="fade-right" data-aos-duration="800">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title">{FormTitle}</h4>
                                </div>
                                <p className="m-r-40">{FormSubtitle}</p>
                            </div>
                            <form action="sendMail/send.php" className="form-contact" id="contactform" data-aos="fade-right" data-aos-duration="800">
                                <fieldset>
                                    <input
                                    className={nameTouched ? (!nameIsValid ? "error" : "") : ""}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                        setNameIsValid( name !== "" );
                                    }} 
                                    onBlur={() => setNameTouched(true)}
                                    value={name} 
                                    type="text" 
                                    name="name" 
                                    id="name" 
                                    placeholder="Name" />
                                </fieldset>
                                <fieldset>
                                    <input 
                                        className={emailTouched ? (!emailIsValid ? "error" : "") : ""}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setEmailIsValid( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) );
                                        }} 
                                        onBlur={() => setEmailTouched(true)}
                                        value={email}
                                        type="email" 
                                        name="mail" 
                                        id="mail" 
                                        placeholder="Email Address"
                                    />
                                </fieldset>
                                <fieldset>
                                    <input
                                        className={phoneTouched ? (!phoneIsValid ? "error" : "") : ""}
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                            setPhoneIsValid(phone !== "");
                                        }} 
                                        onBlur={() => setPhoneTouched(true)}
                                        value={phone} 
                                        type="number" 
                                        name="phone" 
                                        id="phone" 
                                        placeholder="Phone" 
                                    />
                                </fieldset>
                                <fieldset>
                                    <textarea 
                                        className={messageTouched ? (!messageIsValid ? "error message" : "message") : "message"}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                            setMessageIsValid( message !== "" );
                                        }} 
                                        onBlur={() => setMessageTouched(true)}
                                        value={message} 
                                        placeholder="Type your Messege" 
                                        rows="5"
                                        name="message"
                                        id="message">
                                    </textarea>
                                </fieldset>
                                <div className="button-message-container">
                                    <button onClick={e => handleFormSubmit(e)} className="tf-button btn-effect" type="submit"><span className="boder-fade"></span><span className="effect">Send Message</span></button>
                                    {serverMessage &&
                                        <div>{serverMessage}</div>
                                    }
                                </div>
                            </form>
                        </div>
                        <div className="col-xl-7  col-md-12">
                            <div className="contact-details" data-aos="fade-left" data-aos-duration="800">
                                <div className="adress wrap-fx">
                                    <div className="location">
                                        <h6>{LeftTitle}</h6>
                                        <ul>
                                        {
                                            LeftDetails.map(item => (
                                                <li key={item.id}><ReactMarkdown>{item.ListItem}</ReactMarkdown></li>
                                            ))
                                        }
                                        </ul>
                                    </div>
                                    <div className="mail">
                                        <h6>{RightTitle}</h6>
                                        <ul>
                                        {
                                            RightDetails.map(item => (
                                                <li key={item.id}><ReactMarkdown>{item.ListItem}</ReactMarkdown></li>
                                            ))
                                        }
                                        </ul>
                                    </div>
                                </div>
                                <div className="flat-map wow fadeIn animated" data-wow-delay="0.3ms" data-wow-duration="1000ms">
                                    <div dangerouslySetInnerHTML={{__html: location}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;