import React , {useState} from 'react';
import './styles.scss'
import Button from '../../components/button';
import ReactMarkdown from 'react-markdown';

function About(props) {

    const {data} = props;
    const backend = process.env.REACT_APP_BACKEND_URL;

    const [subtitle] = useState(data.Subtitle ?? '');
    const [title] = useState(data.Title ?? '');
    const [description] = useState(data.Description ?? '');
    const [buttonText] = useState((data.Button && data.Button.Text) ?? '');
    const [buttonURL] = useState((data.Button && data.Button.URL) ?? '');
    const [imageAlt] = useState((data.Image && data.Image.Alt) ?? '');
    const [imageSrc] = useState((data.Image && data.Image.Image.data.attributes.formats.medium.url) ?? '');

    return (
        <section className="tf-section tf-about mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-5">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{subtitle}</p>
                                <h4 className="title">{title}</h4>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: description}} />
                            {(buttonText && buttonURL) ? <Button title={buttonText} path={buttonURL}/> : ""}
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            <img src={backend + imageSrc} alt={imageAlt}></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;