import React from 'react';
import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap/home-v3';
import useFetch from '../hooks/useFetch';
import ReactMarkdown from 'react-markdown';

function Portfolio(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/portfolio";
    const query = "?populate=*,TopImage,TopImage.Image,Roadmap,Roadmap.Item,BottomImage,BottomImage.Image,Partners,Partners.Image,Partners.Image.Image";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    const topImageSrc = (data.data.attributes.TopImage && data.data.attributes.TopImage.Image.data.attributes.formats.medium.url) ?? null;
    const topImageAlt = (data.data.attributes.TopImage && data.data.attributes.TopImage.Alt) ?? "";

    const mainText = (data.data.attributes.Text) ?? "";

    const bottomImageSrc = (data.data.attributes.BottomImage && data.data.attributes.BottomImage.Image.data.attributes.formats.medium.url) ?? null;
    const bottomImageAlt = (data.data.attributes.BottomImage && data.data.attributes.BottomImage.Alt) ?? "";

    const bottomTitle = (data.data.attributes.BottomTitle) ?? "";
    const bottomText = (data.data.attributes.BottomText) ?? "";

    return (
        <div className='post-details'>
            <PageTitle title={(data.data.attributes.PageTitle ?? "")} />
            <section className="page-title">
                <div className="image">
                    {topImageSrc ? <img src={backend + topImageSrc} alt={topImageAlt ?? ""} /> : ""}
                </div>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post-details">
                                <div dangerouslySetInnerHTML={{__html: mainText}} />
                                <div className="image">
                                </div>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <RoadMap data={data.data.attributes.Roadmap} />

            <section className="tf-section team-detail ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="image-details" data-aos="fade-right" data-aos-duration="800">
                                {bottomImageSrc ? <img src={backend + bottomImageSrc} alt={bottomImageAlt ?? ""} /> : ""}
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="info-detail" data-aos="fade-left" data-aos-duration="800">
                                <h4 className="name">{bottomTitle}</h4>
                                <div className="box">
                                    <div dangerouslySetInnerHTML={{__html: bottomText}} />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Partner data={dataPartner} /> */}
        </div>
        
    );
}

export default Portfolio;