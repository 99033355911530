import React from 'react';
import PageTitle from '../components/pagetitle';
import About from '../features/about';
import AboutV3 from '../features/about/home-v3';
import useFetch from '../hooks/useFetch';

function AboutTwo(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/about";
    const query = "?populate=*,About,About.Image.Image,About.Button,Section2,Section2.Item,Section3,Section3.Image.Image,Section4,Section4.Item";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    console.log(data);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    return (
        <div className='about-v2'>
            <PageTitle title={data.data.attributes.PageTitle} />
            <About data={data.data.attributes.About} />
            <AboutV3 data={data.data.attributes.Section2} />
            <About data={data.data.attributes.Section3} />
            <AboutV3 data={data.data.attributes.Section4} />
        </div>
    );
}

export default AboutTwo;