import React from 'react';
import PageTitle from '../components/pagetitle';
import useFetch from '../hooks/useFetch';


function PatrolDivsion(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/patrol-division";
    const query = "?populate=*,TopImage.Image";
    const url = backend + endpoint + query;

    const { isPending, error, data } = useFetch(url);

    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    

    const topImageSrc = (data.data.attributes.TopImage && data.data.attributes.TopImage.Image.data.attributes.formats.medium.url) ?? null;
    const topImageAlt = (data.data.attributes.TopImage && data.data.attributes.TopImage.Alt) ?? "";
    const mainText = (data.data.attributes.Text) ?? "";

    return (
        <div className='post-details'>
            <PageTitle title={(data.data.attributes.PageTitle ?? "")} />
            <section className="page-title">
                <div className="image">
                    {topImageSrc ? <img src={backend + topImageSrc} alt={topImageAlt ?? ""} /> : ""}
                </div>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post p-b23">
                                <h4 className="title">{(data.data.attributes.TextTitle ?? "")}</h4>        
                            </div>
                            <div className="post-details">
                                <div dangerouslySetInnerHTML={{__html: mainText}} />
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>

    );
}

export default PatrolDivsion;