import React from 'react';
import AccordionItem from '../features/faq/accordion-item';
import Button from '../components/button';
import PageTitle from '../components/pagetitle';
import useFetch from '../hooks/useFetch';


function Transportation(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/transportation";
    const query = "?populate=*,Transportation.TopImage,Transportation.TopImage.Image,Transportation.Image,Transportation.Image.Image,Transportation.Button,Transportation.Button.Button,Transportation.Checkboxes";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    const topImageSrc = (data.data.attributes.Transportation.TopImage && data.data.attributes.Transportation.TopImage.Image.data.attributes.formats.medium.url) ?? null;
    const topImageAlt = (data.data.attributes.Transportation.TopImage && data.data.attributes.Transportation.TopImage.Alt) ?? "";
    const mainText = (data.data.attributes.Transportation.Text) ?? "";

    const buttonText = (data.data.attributes.Transportation.Button && data.data.attributes.Transportation.Button.Text) ? data.data.attributes.Transportation.Button.Text : "";
    const buttonURL = (data.data.attributes.Transportation.Button && data.data.attributes.Transportation.Button.URL) ? data.data.attributes.Transportation.Button.URL : "";

    const mainImageSrc = (data.data.attributes.Transportation.Image && data.data.attributes.Transportation.Image.Image.data.attributes.formats.medium.url) ?? null;
    const mainImageAlt = (data.data.attributes.Transportation.Image && data.data.attributes.Transportation.Image.Alt) ?? "";

    const checkboxes = (data.data.attributes.Transportation.Checkboxes ?? []);

    return (
        <div className='post-details'>
            
            <PageTitle title={(data.data.attributes.Transportation.PageTitle ?? "")} />
            <section className="page-title">
                <div className="image">
                    {topImageSrc ? <img src={backend + topImageSrc} alt={topImageAlt ?? ""} /> : ""}
                </div>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                                <h4 className="title">{data.data.attributes.Transportation.Title ?? ""}</h4> 
                            <div className="post-details">
                                <div dangerouslySetInnerHTML={{__html: mainText}} />
                                <div className="image">
                                    {mainImageSrc ? <img src={backend + mainImageSrc} alt={mainImageAlt ?? ""} /> : ""}
                                </div>
                            </div>
                            <div className="btn-about center aos-init aos-animate" data-aos="fade-up" data-aos-duration="800">
                                {(buttonText && buttonURL) ? <Button title={buttonText} path={buttonURL}/> : ""}
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
            
            <section className="tf-section faq bg-st2">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="flat-accordion" data-aos="fade-up" data-aos-duration="800">
                                {
                                    checkboxes.slice(0, checkboxes.length/2+1).map(item => (
                                        <AccordionItem key={item.id} item={item} />
                                    ))
                                }

                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="flat-accordion" data-aos="fade-up" data-aos-duration="800">
                                {
                                    checkboxes.slice(checkboxes.length/2, checkboxes.length).map(item => (
                                        <AccordionItem key={item.id} item={item} />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    );
}

export default Transportation;