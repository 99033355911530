import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap';
import useFetch from '../hooks/useFetch';
import ReactMarkdown from 'react-markdown';


function Services(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/services";
    const query = "?populate=*,Title,Image,Image.Image,Text,PageImage,PageImage.Image,Services,Services.Item,BottomLeftImage.Image";
    const url = backend + endpoint + query;

    const {isPending ,error, data} = useFetch(url);
    
    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    
    const topImageSrc = (data.data.attributes.Image && data.data.attributes.Image.Image.data.attributes.formats.medium.url) ?? null;
    const topImageAlt = (data.data.attributes.Image && data.data.attributes.Image.Alt) ?? "";
    const mainText = (data.data.attributes.Text) ?? "";

    const mainImageSrc = (data.data.attributes.PageImage && data.data.attributes.PageImage.Image.data.attributes.formats.medium.url) ?? null;
    const mainImageAlt = (data.data.attributes.PageImage && data.data.attributes.PageImage.Alt) ?? "";
    
    const botImageSrc = (data.data.attributes.BottomLeftImage && data.data.attributes.BottomLeftImage.Image.data.attributes.formats.medium.url) ?? null;
    const botImageAlt = (data.data.attributes.BottomLeftImage && data.data.attributes.BottomLeftImage.Alt) ?? "";
    const botTitle = (data.data.attributes.BottomTitle) ?? "";
    const botText = (data.data.attributes.BottomText) ?? "";
    return (
        <div className='post-details'>
            
            <PageTitle title={(data.data.attributes.Title ?? "")} />

            <section className="page-title">
                <div className="image">
                    {topImageSrc ? <img src={backend + topImageSrc} alt={topImageAlt ?? ""} /> : ""}
                    
                </div>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            
                            <div className="post-details">
                                <div dangerouslySetInnerHTML={{__html: mainText}} />
                                <div className="image">
                                    {mainImageSrc ? <img src={backend + mainImageSrc} alt={mainImageAlt ?? ""} /> : ""}
                                </div>
                            </div>
                        </article>         
                    </div>
                </div>
            </section>

            <RoadMap data={data.data.attributes.Services}/>

            <section className="tf-section team-detail ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="image-details" data-aos="fade-right" data-aos-duration="800">
                                {botImageSrc ? <img src={backend + botImageSrc} alt={botImageAlt ?? ""} /> : ""}
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="info-detail" data-aos="fade-left" data-aos-duration="800">
                                {botTitle ? <h4 className="name">{botTitle}</h4> : ""}
                                <div className="box">
                                    <div dangerouslySetInnerHTML={{__html: botText}} />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;