import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TeamItem from '../team-item';

Team.propTypes = {
    data: PropTypes.object,
};

function Team(props) {

    const {data} = props;

    const [subtitle] = useState((data.Subtitle ?? ''));
    const [title] = useState((data.Title ?? ''));
    const [teamMember] = useState(data.TeamMember ?? []);
    return (
        <section className="tf-section tf-team home-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-51" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{subtitle}</p>
                            <h4 className="title">{title}</h4>
                        </div>
                    </div>
                    {
                        teamMember.map(item => (
                            <div key={item.id} className="col-xl-3 col-md-6 " data-aos="fade-up" data-aos-duration="800">
                                <TeamItem data={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default Team;