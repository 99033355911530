import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

VideoSliderItem.propTypes = {
    item : PropTypes.object,
};

function VideoSliderItem(props) {
    const data = props.data;

    const backend = process.env.REACT_APP_BACKEND_URL;
    const videoUrl = (data.Video && data.Video.data.attributes.url) ?? "";
    const videoPlaceholderUrl = (data.VideoPlaceholder && data.VideoPlaceholder.data && data.VideoPlaceholder.data.attributes.url) ? backend + data.VideoPlaceholder.data.attributes.url : "";
    const buttonText = (data.Button && data.Button.Text) ?? "";
    const buttonUrl = (data.Button && data.Button.URL) ?? "";

    return (
        <div className={`box-slider ${data.classAction}`}>
            <video className='bg-slider' muted playsInline autoPlay poster={videoPlaceholderUrl}> 
                <source src={backend + videoUrl} type="video/mp4" />
            </video>
            <div className="box-slider__main">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-box">
                                <h1 className="title">{data.Title}</h1>
                                <p className="sub-title">{data.Subtitle}</p>
                                <div className="wrap-btn">
                                    <Link to={buttonUrl} className="tf-button-st2 btn-effect" data-toggle="modal" data-target="#popup_bid"><span className="effect">{buttonText}</span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-md-12">
                            <div className="image guardImage">
                                <img src={item.img} alt="cybox" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoSliderItem;