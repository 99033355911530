import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';

TeamItem.propTypes = {
  item: PropTypes.object,  
};

function TeamItem(props) {

    const backend = process.env.REACT_APP_BACKEND_URL;
    const {data} = props;
    const [imageSrc] = useState((data.Image && data.Image.Image.data.attributes.formats.small.url) ?? null);
    const [imageAlt] = useState((data.Image && data.Image.Alt) ?? null);
    const [name] = useState(data.Name ?? "");
    const [position] = useState(data.Title ?? "");
    const [socialMedia] = useState(data.SocialMedia ?? "");

    const iconMap = {
        facebook : "fab fa-facebook-f",
        twitter : "fab fa-twitter",
        linkedin: "fab fa-linkedin-in",
        skype: "fab fa-skype"
    }
    return (
        <div className="team-box">
            <div className="image">
                { imageSrc ? <img src={backend + imageSrc} alt={imageAlt} /> : ''}
            </div>
            <div className="content">
                <div className="h8"><a href="/team-details">{name}</a></div>
                <p>{position}</p>
                <ul className="social">
                    {
                        socialMedia.map(icon => (
                            <li key={icon.id}>
                                { (icon.URL && icon.Platform) ? <Link to={icon.URL}><i className={iconMap[icon.Platform]}></i></Link> : "" }
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default TeamItem;