import React from 'react';
import PageTitle from '../components/pagetitle';
import useFetch from '../hooks/useFetch';


function TermsConditions(props) {
    const backend = process.env.REACT_APP_BACKEND_URL;
    const endpoint = "/api/terms-conditions";
    const query = "?populate=*";
    const url = backend + endpoint + query;

    const { isPending, error, data } = useFetch(url);

    if (isPending) return <div className='extra-height'></div>;
    if (error) return <div className='extra-height'></div>;
    

    const mainText = (data.data.attributes.Text) ?? "";

    return (
        <div className='post-details'>
            <PageTitle title={(data.data.attributes.PageTitle ?? "")} />
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="post-details">
                                <div dangerouslySetInnerHTML={{__html: mainText}} />
                            </div>
                        </article>         
                    </div>
                </div>
            </section>
        </div>

    );
}

export default TermsConditions;