
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import '../src/assets/font/font-awesome.css'
import useFetch from './hooks/useFetch';
import { Helmet } from 'react-helmet';
import HomeOne from "./pages/HomeOne";
import AboutTwo from "./pages/AboutTwo";
import Page404 from "./pages/404";
import Contact from "./pages/Contact";
import History from "./pages/History";
import OurMission from "./pages/OurMission";
import Management from "./pages/Management";
import Services from "./pages/Services";
import Security from "./pages/Security";
import Parking from "./pages/Parking";
import Staffing from "./pages/Staffing";
import Ticketing from "./pages/Ticketing";
import Transportation from "./pages/Transportation";
import Portfolio from "./pages/Portfolio";
import Resume from "./pages/Resume";
import Gallery from "./pages/Gallery";
import Reviews from "./pages/Reviews";
import PatrolDivsion from "./pages/PatrolDivsion";
import Cookies from "./pages/Cookies";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {

    useEffect(() => {
        AOS.init({
          duration : 1000
        }); 
      }, []);
    
      const backend = process.env.REACT_APP_BACKEND_URL;
      const seoEndpoint = "/api/seo";
      const seoQuery = "?&populate=*,seo,seo.metaImage.Image";
      const seoUrl = backend + seoEndpoint + seoQuery;
  
      const {isPending: seoIsPending ,error: seoError, data: seoData} = useFetch(seoUrl);


      const routesEndpoint = "/api/menu";
      const routesQuery = "?&populate=*";
      const routesUrl = backend + routesEndpoint + routesQuery;
  
      const {isPending: routesIsPending ,error: routesError, data: routesData} = useFetch(routesUrl);
      
    if (seoIsPending || seoError || routesIsPending || routesError) return <div className='extra-height'></div>;

    const routes = [
        { isEnabled: routesData.data.attributes.Home.Enable, title: routesData.data.attributes.Home.Title, path: routesData.data.attributes.Home.Path, component: <HomeOne />},
      
        { isEnabled: routesData.data.attributes.About.Enable, title: routesData.data.attributes.About.Title, path: routesData.data.attributes.About.Path, component: <AboutTwo />},
          { isEnabled: routesData.data.attributes.AboutSubmenus[0].Enable, title: routesData.data.attributes.AboutSubmenus[0].Title, path: routesData.data.attributes.AboutSubmenus[0].Path, component: <History />},
          { isEnabled: routesData.data.attributes.AboutSubmenus[1].Enable, title: routesData.data.attributes.AboutSubmenus[1].Title, path: routesData.data.attributes.AboutSubmenus[1].Path, component: <OurMission />},
          { isEnabled: routesData.data.attributes.AboutSubmenus[2].Enable, title: routesData.data.attributes.AboutSubmenus[2].Title, path: routesData.data.attributes.AboutSubmenus[2].Path, component: <Management />},
      
        { isEnabled: routesData.data.attributes.Services.Enable, title: routesData.data.attributes.Services.Title, path: routesData.data.attributes.Services.Path, component: <Services />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[0].Enable, title: routesData.data.attributes.ServicesSubmenus[0].Title, path: routesData.data.attributes.ServicesSubmenus[0].Path, component: <Security />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[1].Enable, title: routesData.data.attributes.ServicesSubmenus[1].Title, path: routesData.data.attributes.ServicesSubmenus[1].Path, component: <Parking />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[2].Enable, title: routesData.data.attributes.ServicesSubmenus[2].Title, path: routesData.data.attributes.ServicesSubmenus[2].Path, component: <Staffing />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[3].Enable, title: routesData.data.attributes.ServicesSubmenus[3].Title, path: routesData.data.attributes.ServicesSubmenus[3].Path, component: <Ticketing />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[4].Enable, title: routesData.data.attributes.ServicesSubmenus[4].Title, path: routesData.data.attributes.ServicesSubmenus[4].Path, component: <Transportation />},
          { isEnabled: routesData.data.attributes.ServicesSubmenus[5].Enable, title: routesData.data.attributes.ServicesSubmenus[5].Title, path: routesData.data.attributes.ServicesSubmenus[5].Path, component: <PatrolDivsion />},
      
        { isEnabled: routesData.data.attributes.Portfolio.Enable, title: routesData.data.attributes.Portfolio.Title, path: routesData.data.attributes.Portfolio.Path, component: <Portfolio />},
          { isEnabled: routesData.data.attributes.PortfolioSubmenus[0].Enable, title: routesData.data.attributes.PortfolioSubmenus[0].Title, path: routesData.data.attributes.PortfolioSubmenus[0].Path, component: <Resume />},
          { isEnabled: routesData.data.attributes.PortfolioSubmenus[1].Enable, title: routesData.data.attributes.PortfolioSubmenus[1].Title, path: routesData.data.attributes.PortfolioSubmenus[1].Path, component: <Gallery />},
          { isEnabled: routesData.data.attributes.PortfolioSubmenus[2].Enable, title: routesData.data.attributes.PortfolioSubmenus[2].Title, path: routesData.data.attributes.PortfolioSubmenus[2].Path, component: <Reviews />},
      
        { isEnabled: routesData.data.attributes.Contact.Enable, title: routesData.data.attributes.Contact.Title, path: routesData.data.attributes.Contact.Path, component: <Contact />},
        { isEnabled: true, title: "Cookies", path: '/cookies', component: <Cookies />},
        { isEnabled: true, title: "Terms & Conditions", path: '/terms-conditions', component: <TermsConditions />},
        { isEnabled: true, title: "Privacy Policy", path: '/privacy-policy', component: <PrivacyPolicy />},
        { isEnabled: true, title: "404 Not Found", path: '/404', component: <Page404 />},
      ]

    return (
        <>

            <Helmet>
                <title>{(seoData.data.attributes.seo.metaTitle) ?? "CISS PRIVATE SECURITY SERVICES"}</title>
                <meta charset="utf-8" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta name="title" content={(seoData.data.attributes.seo.metaTitle) ?? "CISS PRIVATE SECURITY SERVICES"}></meta>
                {/* <link rel="canonical" href={(data.data.attributes.seo.canonicalURL) ?? "https://cissinc.us/"} /> */}
                <meta name="description" content={(seoData.data.attributes.seo.metaDescription) ?? ""} />
                <meta name="keywords" content={(seoData.data.attributes.seo.keywords) ?? ""} />
                <meta name="robots" content={(seoData.data.attributes.seo.metaRobots) ?? "index, follow"} />
            </Helmet>

            <Header menu={routes}/>

            <Routes>
                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))
                }
                <Route path='*' element={<Page404 />} />
            </Routes>

            <Footer />
        </>
    );
}

export default App;
